import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date';
import paginationComponent from '@/components/Pagination'

export default {
    name: 'Poems',
    data() {
        return {
            poems: [],
            totalPoems: 0
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout,
        paginationComponent
    },
    mounted() {
        this.getPoems()
    },
    methods: {
        pageChange: function (nr) {
            this.getPoems(nr);
        },
        getPoems(pageParamter = 1, limitParamter = this.paginationPerPage()) {
            const self = this;
            const limit = '?limit=' + limitParamter;
            const page = '&page=' + pageParamter;
    
            httpAxios({
                url: this.$backendUrl + '/api/v1/poems' + limit + page,
                method: 'GET'
            }).then(response => {
                self.poems = response.data.rows;
                self.totalPoems = response.data.total_data;
            })
        }
    }
}
